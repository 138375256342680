import styled from "@emotion/styled";
import { smNavbarHeight } from "../variables.styles";

const smActionIconSize = "20px";
const mdActionIconSize = "30px";

const smActionIconMargin = "18px";
const mdActionIconMargin = "29px";

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: white;

  width: 100%;
  height: ${smNavbarHeight};
  background-color: ${(props) => props.theme.colors.white};

  padding: 14px ${(props) => props.theme.padding.unit}px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    display: inline-block;
    font-size: 1rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    position: initial;

    height: 131px;
    padding: 21px 31px;
  }
`;

export const LogoContainer = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex: 1;
    text-align: center;
    margin-left: calc(${mdActionIconSize} * 2 + ${mdActionIconMargin});
  }
`;

export const NavbarActions = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${smActionIconMargin};
  }

  img {
    height: ${smActionIconSize};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    > * + * {
      margin-left: ${mdActionIconMargin};
    }

    img {
      height: ${mdActionIconSize};
    }
  }
`;
