import { Link } from "gatsby";
import styled from "@emotion/styled";

export const LogoLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
`;

export const LogoTitle = styled.h1`
  font-size: 28px;
  color: ${(props) => props.theme.colors.black};
  text-transform: uppercase;
  margin: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 48px;
  }
`;

export const LogoSubtitle = styled.h3`
  display: none;
  font-size: 24px;
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`;
