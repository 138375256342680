import React from "react";
import PropTypes from "prop-types";

import { Page, ContentFullWidth, ContentMaxWith } from "./App.styles";

import Header from "./partials/Header";
import Footer from "./partials/Footer";

const App = ({ children, fullWidthContent, displayFooter }) => {
  const ContentWrapper = fullWidthContent ? ContentFullWidth : ContentMaxWith;
  return (
    <Page>
      <Header />
      <ContentWrapper>{children}</ContentWrapper>
      {displayFooter && <Footer />}
    </Page>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  fullWidthContent: PropTypes.bool,
  displayFooter: PropTypes.bool,
};
App.defaultProps = {
  fullWidthContent: false,
  displayFooter: true,
};

export default App;
