import React from "react";
import PropTypes from "prop-types";

import { Container, ProductCount } from "./CartPreview.styles";

import CartIcon from "./CartIcon";

const CartPreview = ({ productCount }) => (
  <Container>
    <CartIcon />
    {productCount > 0 && <ProductCount>{productCount}</ProductCount>}
  </Container>
);

CartPreview.propTypes = {
  productCount: PropTypes.number,
};
CartPreview.defaultProps = {
  productCount: 0,
};

export default CartPreview;
