import React from "react";
import { Location } from "@reach/router";

const withLocationHOC = (Component) => {
  const withLocation = (props) => (
    <Location>
      {({ location }) => <Component location={location} {...props} />}
    </Location>
  );
  return withLocation;
};

export default withLocationHOC;
