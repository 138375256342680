import styled from "@emotion/styled";

const smSize = "24px";
const mdSize = "32px";

const smFontSize = "14px";
const mdFontSize = "18px";

const lineHeightDif = "4px";

export const Container = styled.div`
  position: relative;
`;

export const ProductCount = styled.span`
  position: absolute;
  width: ${smSize};
  height: ${smSize};
  top: -13px;
  right: -16px;

  background-color: ${(props) => props.theme.colors.black};
  border-radius: 50%;
  color: white;
  border: 2px solid ${(props) => props.theme.colors.white};

  font-size: ${smFontSize};
  text-align: center;
  line-height: calc(${smSize} - ${lineHeightDif});

  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: ${mdSize};
    height: ${mdSize};
    top: -16px;
    right: -18px;

    font-size: ${mdFontSize};
    line-height: calc(${mdSize} - ${lineHeightDif});
  }
`;
