import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { maxWidth, container } from "@mixins";

export const Page = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const Content = css`
  ${container}

  position: relative;
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
`;

export const ContentFullWidth = styled.main`
  ${Content}
`;

export const ContentMaxWith = styled.main`
  ${maxWidth}

  ${Content}
`;
