import React from "react";
import { AuthContext } from "contexts/AuthContext";

const withAuth = (Component) => {
  const withAuthHOC = (props) => (
    <AuthContext.Consumer>
      {(sessionContext) => <Component {...props} {...sessionContext} />}
    </AuthContext.Consumer>
  );

  return withAuthHOC;
};

export default withAuth;
