import React from "react";

import { StyledHeader } from "./Header.styles";

import Navbar from "./Navbar";
import Breadcrumb from "./Breadcrumb";

const Header = () => (
  <StyledHeader>
    <Navbar />
    <Breadcrumb />
  </StyledHeader>
);

export default Header;
