import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { StyledNav, StyledOl, StyledLi } from "./Breadcrumb.styles";

import capitaliseFirstLetter from "utils/capitaliseFirstLetter";

import withLocation from "components/withLocation";

const Breadcrumb = ({ location }) => {
  if (location.pathname === "/") {
    return null;
  }

  let elements = location.pathname.slice(1).split("/");

  // Arrange array to proper display
  elements = ["Home", ...elements];
  elements = elements.filter((element) => element !== "");

  return (
    <StyledNav>
      <StyledOl>
        {elements.map((path, index) => {
          const getHref = () => {
            if (index === 0) {
              return "/";
            }

            return `/${elements.slice(1, index + 1).join("/")}`;
          };

          return (
            <StyledLi key={`${path}-${index}`}>
              <Link to={getHref()}>
                {capitaliseFirstLetter(path.split("-").join(" "))}
              </Link>
              {index < elements.length - 1 ? <span>&nbsp;/&nbsp;</span> : null}
            </StyledLi>
          );
        })}
      </StyledOl>
    </StyledNav>
  );
};

Breadcrumb.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withLocation(Breadcrumb);
