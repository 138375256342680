import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { LEGAL_NOTE_ROUTE } from "@routes";

import {
  Background,
  StyledFooter,
  Logo,
  Socials,
  LegalContainer,
  LegalNote,
  Copyright,
} from "./Footer.styles";

import emailIcon from "images/email-icon.svg";
import facebookIcon from "images/facebook-icon.svg";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <Background>
        <StyledFooter>
          <div>
            <Logo>
              {data.site.siteMetadata.title}
              <span>{data.site.siteMetadata.description}</span>
            </Logo>
            <LegalContainer>
              <LegalNote to={LEGAL_NOTE_ROUTE}>Nota Legal</LegalNote>
              <Copyright>&copy; 2019 La Vilaine.es</Copyright>
            </LegalContainer>
            <Socials>
              <a href="mailto:lavilaine.es@gmail.com" rel="noopener noreferrer">
                <img src={emailIcon} alt="Envelope" />
              </a>
              <a
                href="https://www.facebook.com/lavilaine.es/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="Facebook icon" />
              </a>
            </Socials>
          </div>
        </StyledFooter>
      </Background>
    )}
  />
);

export default Footer;
