import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { LogoLink, LogoTitle, LogoSubtitle } from "./Logo.styles";

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => (
      <LogoLink to="/">
        <LogoTitle>{data.site.siteMetadata.title}</LogoTitle>
        <LogoSubtitle>{data.site.siteMetadata.description}</LogoSubtitle>
      </LogoLink>
    )}
  />
);

export default Logo;
