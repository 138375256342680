import styled from "@emotion/styled";
import { smNavbarHeight } from "./variables.styles";

export const StyledHeader = styled.header`
  padding-top: ${smNavbarHeight};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-top: 0;
  }
`;
