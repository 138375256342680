import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { CART_ROUTE, PROFILE_ROUTE, LOGIN_ROUTE } from "@routes";

import { Container, LogoContainer, NavbarActions } from "./Navbar.styles";

import withAuth from "contexts/withAuth";

import Logo from "components/Logo";
import { withShoppingCart } from "components/ShoppingCart";

import ProfileIcon from "./ProfileIcon";
import CartPreview from "./CartPreview";

const Navbar = ({ isAuthenticated, cart }) => (
  <Container>
    <LogoContainer>
      <Logo />
    </LogoContainer>

    <NavbarActions>
      <Link to={isAuthenticated ? PROFILE_ROUTE : LOGIN_ROUTE}>
        <ProfileIcon />
      </Link>
      <Link to={CART_ROUTE}>
        <CartPreview productCount={cart.length} />
      </Link>
    </NavbarActions>
  </Container>
);

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  cart: PropTypes.array.isRequired,
};

export default withAuth(withShoppingCart(Navbar));
