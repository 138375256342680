import styled from "@emotion/styled";
import { maxWidth, container } from "@mixins";
import { Link } from "gatsby";

const iconSize = "28px";

export const Background = styled.footer`
  background-color: ${(props) => props.theme.colors.semiBlack};
`;

export const StyledFooter = styled.div`
  ${maxWidth}
  ${container}

  padding-top: 10px;
  padding-bottom: 10px;

  width: 100%;
  min-height: 62px;

  color: ${(props) => props.theme.colors.white};

  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
    flex-basis: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const Logo = styled.span`
  order: 1;
  flex: 1;
  text-transform: uppercase;

  font-size: 20px;

  > span {
    display: block;
    font-size: 12px;
    text-transform: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-basis: 33.33%;
  }
`;

export const Socials = styled.div`
  order: 2;
  text-align: right;

  img {
    width: ${iconSize};
  }

  > * + * {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    order: 3;
    flex: 1;
    flex-basis: 33.33%;
  }
`;

export const LegalContainer = styled.div`
  order: 3;
  flex: 1;
  flex-basis: 100%;

  margin-top: ${(props) => props.theme.padding.unit / 2}px;
  padding-top: ${(props) => props.theme.padding.unit / 2}px;
  border-top: 1px solid ${(props) => props.theme.colors.white};

  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    order: 2;
    flex-basis: 33.33%;

    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
`;

export const Copyright = styled.span`
  display: block;
  font-size: 14px;
`;

export const LegalNote = styled(Link)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
