import styled from "@emotion/styled";
import { maxWidth, container } from "@mixins";

export const StyledNav = styled.div`
  width: 100%;
  min-height: 45px;

  line-height: 25px;

  background-color: ${(props) => props.theme.colors.lightGrey};

  > ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-type: none;
  }
`;

export const StyledOl = styled.ol`
  ${maxWidth}
  ${container}

  margin-left: 0;
`;

export const StyledLi = styled.li`
  display: inline-block;

  > a {
    font-size: 14px;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  &:last-child {
    > a {
      color: ${(props) => props.theme.colors.darkGrey};
    }
  }
`;
