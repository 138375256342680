import { css } from "@emotion/react";
import theme from "@theme";

export const maxWidth = css`
  @media (min-width: ${theme.breakpoints.lg}) {
    width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const container = css`
  padding-left: ${theme.padding.unit}px;
  padding-right: ${theme.padding.unit}px;
`;
